<template>
  <div>
    <add-gallery-image
      :is-add-gallery-image-sidebar-active.sync="isAddGalleryImageSidebarActive"
       :imageStatus="imageStatus"
      @clickedAddGalleryData="clickedAddGalleryData"
    />
    <create-folder-sidebar
      :iscreateFolderSidebarActive="iscreateFolderSidebarActive"
      :folderType="folderType"
      @createfolderSuccess="createfolderSuccess"
    />
    <!-- :breadCrumb="breadCrumb" -->
    <add-edit-gallery
      :is-add-edit-gallery-sidebar-active.sync="isAddEditGallerySidebarActive"
      :editgalleryid="editgalleryid"
      :editgalleryName="editgalleryName"
      :editgalleryimg="editgalleryimg"
      @clickeditimagegallerydata="clickeditimagegallerydata"
    />

    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-breadcrumb :items="items" />
        </b-col>
        <b-col cols="12" md="6">
          <div>
            <b-row>
              <b-col cols="12" md="4"> </b-col>
              <b-col cols="12" md="8">
                <div class="d-flex align-items-center justify-content-end">
                  <span class="text-nowrap">
                    <b-button
                      v-if="removeButtons == true"
                      variant="primary"
                      @click="openCreateFolderSideBar"
                      style="font-size: 12px"
                    >
                      Create Folder
                    </b-button>
                  </span>
                  <span class="text-nowrap" style="margin-left: 1%">
                    <b-button
                      v-if="removeButtons == true"
                      variant="primary"
                      @click="openUploadImageSideBar"
                      style="font-size: 12px"
                    >
                      Upload
                    </b-button>
                  </span>
                  <!-- <span style="margin-left: 1%; width: 45%">
                      <b-form-input
                        class="d-inline-block mr-1"
                        placeholder="Search..."
                      />
                    </span> -->
                </div>
              </b-col>
            </b-row>
          </div>
          <!-- <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div> -->
        </b-col>
      </b-row>
    </div>

    <div class="m-2">
      <div>
        <b-modal
          id="modal-rename"
          hide-footer
          size="sm"
          centered
          title="Rename Folder"
        >
          <div class="modal_content">
            <b-form-group>
              <b-form-input
                v-model="rename.name"
                placeholder="Rename"
                type="text"
              />
            </b-form-group>
            <div class="modal-footer">
              <b-button
                @click="renameSave"
                style="margin-left: 7px"
                variant="primary"
                >Save
              </b-button>
            </div>
          </div>
        </b-modal>
        <b-row class="box_container">
          <b-col
            md="1"
            lg="1"
            v-for="(folder, index) in allFolder"
            :key="index"
          >
            <div class="box_inner" @click="folderChangeFunction">
              <b-link
                :to="{
                  path: '/folder/' + folder._id + '/' + folder.name,
                }"
              >
                <img style="width: 100%" src="../img/folder.2e23d600.png" />
              </b-link>

              <b-dropdown variant="link" no-caret>
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="14"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item
                  v-b-modal.modal-rename
                  @click="renameFolder(folder._id, folder.name)"
                >
                  <feather-icon size="14" icon="EditIcon" />
                  <span class="align-middle ml-50">Rename</span>
                </b-dropdown-item>
                <b-dropdown-item @click="deleteFolder(folder._id)">
                  <feather-icon size="14" icon="TrashIcon" />
                  <span class="align-middle ml-50">Delete</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <div>{{ folder.name }}</div>
          </b-col>
        </b-row>
      </div>
    </div>

    <div class="m-2">
      <h1>{{ this.$route.params.name + " Images" }}</h1>
      <div>
        <b-row class="match-height">
          <b-col
            md="2"
            lg="2"
            style="padding: 10px; position: relative"
            v-for="galleryimg in folderImages"
            :key="galleryimg.img_id"
            v-lazy-container="{
              selector: 'img',
              error: 'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
              loading:
                'https://i.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.webp',
            }"
          >
            <span
              style="position: absolute; right: 0"
              v-if="galleryimg.showcheckbox == true"
            >
              <b-form-checkbox v-on:change="checkboxdata(galleryimg._id)">
              </b-form-checkbox>
            </span>
            <div v-if="galleryimg.resize">
              <img
                fluid
                class="mb-2"
                style="
                  width: 144px;
                  height: 150px;
                  border: 2px solid #faf9f6;

                  border-radius: 10px;
                "
                :data-src="galleryimg.resize[0].img"
              />
            </div>
            <div v-else>
              <img
                fluid
                class="mb-2"
                style="
                  width: 144px;
                  height: 150px;
                  border: 2px solid #faf9f6;

                  border-radius: 10px;
                "
                v-b-tooltip.hover.bottom="imageTooltipText"
                @mouseover="mouseOver(galleryimg.image_name)"
                :data-src="galleryimg.gallery_img"
              />
            </div>

            <div class="d-flex flex-stack flex-wrap">
              <div class="d-block w-100">
                <feather-icon
                  v-if="removeButtons == true"
                  style="position: relative; bottom: 14%; left: 10%"
                  icon="EditIcon"
                  @click="
                    editgalleryimage(
                      galleryimg._id,
                      galleryimg.image_name,
                      galleryimg.gallery_img
                    )
                  "
                />
                <feather-icon
                  v-if="removeButtons == true"
                  style="position: relative; bottom: 14%; left: 14%"
                  icon="TrashIcon"
                  @click="deletegalleryimg(galleryimg._id)"
                />
              </div>
              <div class="d-block w-100">
                {{ galleryimg.image_name }}
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BFormGroup,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
} from "bootstrap-vue";
import vSelect from "vue-select";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import AddGalleryImage from "../app/list/users-list/AddGalleryImage.vue";
import AddEditGallery from "../app/list/users-list/AddEditGallery.vue";
import createFolderSidebar from "../app/list/users-list/createFolderSidebar.vue";
import { BBreadcrumb } from "bootstrap-vue";
import axios from "axios";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BBreadcrumb,
    BFormGroup,
    BCard,
    AddEditGallery,
    createFolderSidebar,
    AddGalleryImage,
    ToastificationContent,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    vSelect,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      imageStatus: this.$route.params.folderId,
      folderType: this.$route.params.folderId,
      editgalleryName: "",
      editgalleryid: "",
      editgalleryimg: "",
      folderId: this.$route.params.folderId,
      iscreateFolderSidebarActive: false,
      isAddGalleryImageSidebarActive: false,
      isAddEditGallerySidebarActive: false,
      removeButtons: "",
      allFolder: [],
      folderImages: [],
      rename: {
        id: "",
        name: "",
      },
      alertMessageToast: "",
      items: [
        {
          text: "Gallery",
          // name: "Classes",
          to: { name: "Gallery" },
        },
        {
          text: this.$route.params.name,
          // name: "Classes",
          to: { path: "folder" + this.$route.params.folderId },
        },
      ],
    };
  },
  mounted() {
    var newPermission = JSON.parse(localStorage.getItem("permission"));
    for (let index = 0; index < newPermission.permission.length; index++) {
      if (newPermission.permission[index] == "manage_gallery") {
        this.removeButtons = true;
        // this.forcRenderSubject();
        // console.log("this.removeButtons", this.removeButtons);
        break;
      } else {
        this.removeButtons = false;
      }
    }
  },
  created() {
    // console.log("this.$route.params",this.$route.params);
    this.getFolderData();
    this.getAllFolder();
  },
  methods: {
    renameSave() {
      // console.log("rename", this.rename);
      axios
        .post(
          process.env.VUE_APP_API_URL + "/gallery/rename-folder",
          this.rename,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          if (response.data.message == "Folder renamed successfully") {
            this.alertMessageToast = response.data.message;
            // this.allFolder = response.data.data;
            this.getAllFolder();
            this.showToast("success");
            this.$root.$emit("bv::hide::modal", "modal-rename");

            // this.$root.$emit("bv::hide::modal", "modal-xl");
          } else {
            this.alertMessageToast = "Folder not created try again";
            this.showToast("danger");
            this.$root.$emit("bv::hide::modal", "modal-rename");

            //
          }
        });
    },
    openUploadImageSideBar() {
      this.imageStatus = this.$route.params.folderId;
      this.isAddGalleryImageSidebarActive = true;
    },
    openCreateFolderSideBar() {
      this.folderType = this.$route.params.folderId;
      this.iscreateFolderSidebarActive = true;
    },
    folderChangeFunction() {
      // alert(0);
      this.getFolderData();
      this.getAllFolder();
    },
    showToast(variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Notification",
          icon: "BellIcon",
          text: this.alertMessageToast,
          variant,
        },
      });
    },
    renameFolder(id, name) {
      // alert(0);
      this.rename = {
        id: id,
        name: name,
      };
    },
    deleteFolder(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "Do You want to delete folder",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          let file = {
            id: id,
          };
          axios
            .post(
              process.env.VUE_APP_API_URL + "/gallery/delete-folder",
              file,
              {
                headers: {
                  Authorization: "Bearer " + localStorage.getItem("_t"),
                },
              }
            )
            .then((response) => {
              if (response.data.message == "Folder deleted successfully") {
                // this.allFolder = response.data.data;
                this.getAllFolder();
                this.alertMessageToast = response.data.message;
                this.showToast("success");
              } else {
                this.alertMessageToast = "Folder not created try again";
                this.showToast("danger");
              }
            });
        }
      });
    },
    getAllFolder() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            `/getFolder/data/${this.$route.params.folderId}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((res) => {
          this.allFolder = res.data;
        });
    },
    getFolderData() {
      axios
        .get(
          process.env.VUE_APP_API_URL +
            `/folder-data/${this.$route.params.folderId}`,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          // console.log("response", response.data);
          this.folderImages = response.data;
        });
    },
    clickedAddGalleryData: function (gallery_data) {
      //   console.log(gallery_data)
      axios
        .post(process.env.VUE_APP_API_URL + "/addgallerydata", gallery_data)
        .then((response) => {
            this.getFolderData();
        });
    },
    createfolderSuccess(status) {
      if (status) {
        this.iscreateFolderSidebarActive = false;
        this.getAllFolder();
      } else {
        this.iscreateFolderSidebarActive = false;
        this.getAllFolder();
      }
    },
    clickeditimagegallerydata: function (edit_gallery_data) {
      // console.log(edit_gallery_data)
      axios
        .post(
          process.env.VUE_APP_API_URL + "/edit_gallery_data",
          edit_gallery_data
        )
        .then((response) => {});
    },
  },
};
</script>
<style>
.box_container .box_inner .dropdown button {
  padding: 0 !important;
}
.box_container .box_inner {
  display: flex;
  justify-content: flex-start;
}
.box_container .dropdown-menu {
  min-width: 8rem;
  margin-top: -17px !important;
}
.box_container .dropdown a.dropdown-item {
  padding: 0px 8px;
}
</style>

